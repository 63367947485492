import { lazy, Suspense } from "react"
import { createBrowserRouter, defer, redirect } from "react-router-dom"

import { authApi } from "./api/auth"
import { brandsApi } from "./api/brands"
import { customersApi } from "./api/customers"
import { disputesApi } from "./api/disputes"
import { internalEventsApi } from "./api/events"
import { incomingInquiriesApi } from "./api/inquiries"
import { sessionsApi } from "./api/sessions"
import { store } from "./api/store"
import { ticketsApi } from "./api/ticket"
import { venuesApi } from "./api/venues"
import { BackgroundEllipse } from "./components/BackgroundEllipse"
import { customContainerEllipse } from "./components/BackgroundEllipse/BackgroundEllipse.css"
import { ErrorPage } from "./screens/ErrorPage"
import { HeaderAuth } from "./screens/Header"
import { baseHomeContainer } from "./screens/Home/Home.css"

const CompletedEvents = lazy(() => import("./screens/CompletedEvents/index"))
const IDVerificationStatus = lazy(() => import("./screens/IDVerificationStatus/index"))
const HomePageEvents = lazy(() => import("./screens/HomePageEvents/index"))
const ResetPassword = lazy(() => import("./screens/ResetPassword/index"))
const Home = lazy(() => import("./screens/Home/index"))
const Brands = lazy(() => import("./screens/Brands/index"))
const EventAlternativeViews = lazy(
  () => import("./screens/EventAlternativeViews/index")
)
const CoBrands = lazy(() => import("./screens/CoBrands/index"))
const TicketSwapper = lazy(() => import("./screens/TicketSwapper/index"))
const Disputes = lazy(() => import("./screens/Disputes/index"))
const SessionManagement = lazy(() => import("./screens/SessionManagement/index"))
const IncomingEnquiries = lazy(() => import("./screens/IncomingEnquiries/index"))
const IdVerificationManagement = lazy(() => import("./screens/IdVerificationManagement/index"))
const CreateAccount = lazy(() => import("./screens/CreateAccount/index"))
const UserManagement = lazy(() => import("./screens/UserManagement/index"))
const Venues = lazy(() => import("./screens/Venues/index"))
const BrandVerification = lazy(() => import("./screens/BrandVerification/index"))

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HeaderAuth />,
    errorElement: <ErrorPage />,
    loader: async (data) => {
      const auth = store.dispatch(authApi.endpoints.getSession.initiate())
      auth.unsubscribe()
      try {
        const authResponse = await auth.unwrap()
        if (
          !([
            "ttf_team",
            "tf_developer",
            "tf_support_admin",
            "tf_client_support_admin"
          ].includes(authResponse.data.tfRole))
        ) {
          throw new Error("Not enough permission")
        }
        return defer({
          auth: authResponse,
        })
      } catch (e) {
        if (data.request.url.includes("/login")) {
          return defer({
            auth: Promise.reject(e),
          })
        }
        return redirect("/login")
      }
    },
    children: [
      {
        path: "/login",
        element: (
          <Suspense
            fallback={
              <div className={baseHomeContainer}>
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseA}
                />
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseB}
                />
              </div>
            }
          >
            <Home />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Home/index")
          return {}
        },
      },
      {
        path: "/reset-password",
        element: (
          <Suspense
            fallback={
              <div className={baseHomeContainer}>
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseA}
                />
                <BackgroundEllipse
                  className={customContainerEllipse.containerEllipseB}
                />
              </div>
            }
          >
            <ResetPassword />
          </Suspense>
        ),
        loader: () => {
          import("./screens/ResetPassword/index")
          return {}
        },
      },
      {
        path: "/",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <HomePageEvents />
          </Suspense>
        ),
        loader: () => {
          import("./screens/HomePageEvents/index")
          const params = new URLSearchParams(window.location.search)
          const internalEvents = store.dispatch(
            internalEventsApi.endpoints.getInternalEvents.initiate({
              search_query: params.get('searchText') || "",
              limit: Number(params.get('pageSize')) || 10,
              page: Number(params.get('pageIndex')) + 1,
            })
          )
          internalEvents.unsubscribe()
          return defer({
            internalEvents: internalEvents.unwrap(),
          })
        },
      },
      {
        path: "/completed-events",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <CompletedEvents />
          </Suspense>
        ),
        loader: () => {
          import("./screens/CompletedEvents/index")
          const params = new URLSearchParams(window.location.search)
          const completedEvents = store.dispatch(
            internalEventsApi.endpoints.getCompletedEvents.initiate({
              search_query: params.get('searchText') || "",
              limit: Number(params.get('pageSize')) || 10,
              page: Number(params.get('pageIndex')) + 1,
            })
          )
          return defer({
            completedEvents: completedEvents.unwrap(),
          })
        },
      },
      {
        path: "/brands",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <Brands />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Brands/index")
          const params = new URLSearchParams(window.location.search)
          const brands = store.dispatch(
            brandsApi.endpoints.getBrands.initiate({
              search_query: params.get('searchText') || "",
              limit: Number(params.get('pageSize')) || 10,
              page: Number(params.get('pageIndex')) + 1,
            })
          )
          return defer({
            brands: brands.unwrap(),
          })
        },
      },
      {
        path: "/event-alternative-views",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <EventAlternativeViews />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Brands/index")
          const params = new URLSearchParams(window.location.search)
          const brands = store.dispatch(
            brandsApi.endpoints.getBrands.initiate({
              search_query: params.get('searchText') || "",
              limit: Number(params.get('pageSize')) || 10,
              page: Number(params.get('pageIndex')) + 1,
            })
          )
          return defer({
            brands: brands.unwrap(),
          })
        },
      },
      {
        path: "/co-brands",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <CoBrands />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Brands/index")
          const partnerships = store.dispatch(
            brandsApi.endpoints.getPartnerships.initiate()
          )
          return defer({
            partnerships: partnerships.unwrap(),
          })
        },
      },
      {
        path: "/ticket-swapper",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <TicketSwapper />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/TicketSwapper/index")
          const params = new URLSearchParams(window.location.search)
          const hash = params.get('hash')
          if (hash) {
            const ticket = store.dispatch(
              ticketsApi.endpoints.getTicket.initiate({
                hash,
              })
            )
            return defer({
              ticket: ticket.unwrap(),
            })
          }
          return defer({
            ticket: await Promise.resolve()
          })
        },
      },
      {
        path: "/disputes",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <Disputes />
          </Suspense>
        ),
        loader: () => {
          import("./screens/Disputes/index")
          const params = new URLSearchParams(window.location.search)
          const disputes = store.dispatch(
            disputesApi.endpoints.getDisputes.initiate({
              search_query: params.get('searchText') || "",
              limit: Number(params.get('pageSize')) || 10,
              page: Number(params.get('pageIndex')) + 1,
            })
          )
          return defer({
            disputes: disputes.unwrap()
          })
        },
      },
      {
        path: "/session-management",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <SessionManagement />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/SessionManagement/index")
          const params = new URLSearchParams(window.location.search)
          const email = params.get('email')
          if (email) {
            const sessions = store.dispatch(
              sessionsApi.endpoints.getSessions.initiate({
                email,
              })
            )
            return defer({
              sessions: sessions.unwrap(),
            })
          }
          return defer({
            sessions: await Promise.resolve()
          })
        },
      },
      {
        path: "/incoming-enquiries",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <IncomingEnquiries />
          </Suspense>
        ),
        loader: () => {
          import("./screens/IncomingEnquiries/index")
          const params = new URLSearchParams(window.location.search)
          const inquiries = store.dispatch(
            incomingInquiriesApi.endpoints.getIncomingInquiries.initiate({
              page: Number(params.get('pageIndex')) + 1,
              limit: Number(params.get('pageSize')) || 10,
              search: params.get('searchText') || "",
              mine: params.get('mine') === "true"
            })
          )
          return defer({
            inquiries: inquiries.unwrap(),
          })
        },
      },
      {
        path: "/id-verification-status",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <IDVerificationStatus />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/IDVerificationStatus/index")
          const params = new URLSearchParams(window.location.search)
          const search_query = params.get('searchText')
          const limit = Number(params.get('pageSize'))
          const page = Number(params.get('pageIndex')) + 1
          if (search_query && limit && page) {
            const idVerificationStatus = store.dispatch(
              customersApi.endpoints.getVerificationStatus.initiate({
                search_query,
                limit,
                page,
              })
            )
            return defer({
              idVerificationStatus: idVerificationStatus.unwrap(),
            })
          }
          return defer({
            idVerificationStatus: await Promise.resolve(),
          })
        },
      },
      {
        path: "/bypass-id-verification",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <IdVerificationManagement />
          </Suspense>
        ),
        loader: () => {
          import("./screens/IdVerificationManagement/index")
          const params = new URLSearchParams(window.location.search)
          const customers = store.dispatch(
            customersApi.endpoints.getVerificationManagement.initiate({
              page: Number(params.get("pageIndex")) + 1,
              limit: Number(params.get("pageSize")) || 10,
              search: params.get("searchText") || "",
            })
          )
          return defer({
            customers: customers.unwrap(),
          })
        },
      },
      {
        path: "/create-account",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <CreateAccount />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/CreateAccount/index")
          return {}
        },
      },
      {
        path: "/user-management",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <UserManagement />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/UserManagement/index")
          const params = new URLSearchParams(window.location.search)
          const email = params.get('email')
          if (email) {
            const user = store.dispatch(
              customersApi.endpoints.getUser.initiate({
                email,
              })
            )
            return defer({
              user: user.unwrap(),
            })
          }
          return defer({
            user: await Promise.resolve()
          })
        }
      },
      {
        path: "/venues",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <Venues />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/Venues/index")
          const params = new URLSearchParams(window.location.search)
          const venues = store.dispatch(
            venuesApi.endpoints.getVenues.initiate({
              search_query: params.get('searchText') || "",
              limit: Number(params.get('pageSize')) || 10,
              page: Number(params.get('pageIndex')) + 1,
            })
          )
          return defer({
            venues: venues.unwrap(),
          })
        }
      },
      {
        path: "/brand-verification",
        element: (
          <Suspense fallback={<div className={baseHomeContainer} />}>
            <BrandVerification />
          </Suspense>
        ),
        loader: async () => {
          import("./screens/BrandVerification/index")
          const params = new URLSearchParams(window.location.search)
          if (params.get('brandId')) {
            const brand = store.dispatch(
              brandsApi.endpoints.getBrandVerification.initiate({
                brandId: params.get('brandId') || "",
              })
            )
            return defer({
              brand: brand.unwrap(),
            })
          }
          return defer({
            brand: await Promise.resolve()
          })
        },
      },
    ],
  },
])
